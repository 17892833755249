import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useContext } from "react";
import { Breadcrumb } from "../../components/Breadcrumb";
import { Comments } from "../../components/Comments";
import { Google } from "../../components/Google";
import { PageHeader } from "../../components/PageHeader";
import { LatestPosts } from "../../components/pages/home/LatestPosts";
import { Period } from "../../components/Period";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";
import { Watchlist } from "../../components/Watchlist";
import ThemeContext from "../../context/ThemeContext";
import { generateRandomColor } from "../../util/colors";
import { routes } from "../../util/routes";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImageDataByHandle } from "../../util/image";
import { SupportMe } from "../../components/SupportMe";

interface QA {
  question: string;
  answer: string[] | React.ReactElement;
}

interface Goals {
  percentage?: number;
  goal: string;
  excerpt?: string;
  tasks?: string[];
}

const goals: Goals[] = [
  {
    percentage: 100,
    goal: "Inspire others",
    tasks: [
      "Got sent a message expressing how much my article inspired them. I am ecstatic!",
    ],
  },
  {
    percentage: 15,
    goal: "Complete GBA emulator",
    tasks: [
      "Complete ARM7TDMI CPU",
      "Complete LCD",
      "Write SPU",
      "Correct memory timings",
    ],
  },
  {
    percentage: 5,
    goal: "Write more interesting articles",
    tasks: [
      "Come up with more interesting things to write about, but they take so long to accurately pen down.",
    ],
  },
  {
    percentage: 0,
    goal: "Demo the power of Flutter and Golang",
    tasks: ["Trust me, what I have in store is amazing!"],
  },
  {
    percentage: 20,
    goal: "Get out the house and sleep more.",
    tasks: [
      "I should make some friends.",
      "Find some cool places to go.",
      "Get a full eight hours of sleep.",
    ],
  },
];

const questions: QA[] = [
  {
    question: "Favorite programming language?",
    answer: [
      "I'm going to say Java. I feel at home writing it, and anything I build feels concise and structured.",
    ],
  },
  {
    question: "How many hours of sleep you get a night?",
    answer: (
      <pre className="bg-gray-100 dark:bg-gray-900 dark:text-gray-300 font-semibold p-2 rounded">
        <code className="font-mono">
          const hoursOfSleep = 3;
          <br />
          console.log(hoursOfSleep {"<"} 8 ? <br />
          {"  "}'Not Enough' : 'Just Right');
        </code>
      </pre>
    ),
  },
  {
    question: "Favorite anime?",
    answer: [
      "It has to be between Toaru or HxH. With HxH, seeing a shōnen tell a story on a massive scale while staying narratively coherent is beyond impressive.",
      "Side characters can get about 8 minutes of screen time and out-develop many anime's main characters, but I won't say any names.",
    ],
  },
  {
    question: "What hardware / software do you use?",
    answer: [
      "My MacBook is for web development or any low-power tasks. My desktop (AMD Ryzen 5, NVIDIA 2060) is for beefy tasks that would kill my Macbook. 😨",
    ],
  },
  {
    question: "Your favorite programming project?",
    answer: [
      "I have to go with emulators. Retro game consoles have a big part of my childhood, not to mention the countless ones I have burned up, shorted out, or outright broken.",
      "I get a massive sense of satisfaction when I develop an emulator and have a game boot up while holding the original copy that I played years ago.",
      "Not to mention that developing an emulator is one pinnacle of computer science and research beyond just the programming, with a dabble of electrical engineering thrown in the mix. Don't you get excited thinking about it!",
    ],
  },
  {
    question: "Hobbies",
    answer: [
      "When not programming, I enjoy watching anime or reading manga while critiquing its narrative's strengths or flaws.",
    ],
  },
];

interface Job {
  logoSrc: string;
  workDates: string;
  reaction?: string;
  name: string | JSX.Element;
  jobTitle: string;
  className?: string;
  alt?: string;
}

// TODO REPLACE
const jobs: Job[] = [
  {
    logoSrc: "https://media.graphassets.com/75jQIzW8SQqkxCCIFTRT",
    workDates: "Jul 2021 - Present",
    name: <Google />,
    jobTitle: "Software Engineer",
  },
  {
    logoSrc: "https://media.graphassets.com/Vy8vMIDVRX6iAYEPosty",
    workDates: "Jul 2020 - Jul 2021",
    name: "Amazon Web Services",
    jobTitle: "Software Development Engineer",
  },
  {
    logoSrc: "cWATksOwTQGUdXZIIAVY",
    workDates: "Mar 2017 - Mar 2020",
    name: "Hamilton Multimedia LLC",
    jobTitle: "Software Engineer",
  },
  {
    logoSrc: "GZYFbJlAR5yCfPviRL6V",
    workDates: "Apr 2019 - Oct 2019",
    name: "Air Care Companies Inc.",
    jobTitle: "Technical Developer",
  },
];

const Me = () => {
  const { dark } = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
          github
          linkedin
          reddit
          twitter
        }
      }
      allGraphCmsAsset(
        filter: {
          handle: {
            in: [
              "cWATksOwTQGUdXZIIAVY"
              "GZYFbJlAR5yCfPviRL6V"
              "oxKRhCGKSk2MFJZNIVTE"
            ]
          }
          stage: { eq: PUBLISHED }
        }
      ) {
        edges {
          node {
            id
            gatsbyImageData(quality: 70, placeholder: BLURRED)
            handle
          }
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="About Me"
        description="Learn my story, future goals, and some personal fun facts."
      />
      <Breadcrumb activeLabel="About Me" />

      <PageHeader
        title="About Me"
        periodColor={generateRandomColor(2)}
        description="My hero's journey"
      />

      <Section className="dark:text-gray-300 text-md mx-auto max-w-5xl">
        <p className="mb-5">
          Hi, I'm Gregory Gaines, a full-stack software engineer and aspiring
          blogger who specializes in building serverless microservices. My goal
          is to make the internet more accessible and fun.
        </p>

        <p className="mb-5">
          I started programming at 12 years with C++ and Java. I created binary
          search trees and hacked almost every video game console I had. Soon
          after, I learned about emulation which jump-started my passion for
          computer science.
        </p>

        <p className="mb-5">
          My first jobs were at startups working on simple WordPress / PHP sites
          with HTML, CSS, and JS. Then, I got a taste of FAANG with a great job
          at Amazon Web Services where I got to see the true power of the cloud
          at work. Next, I moved on to <Google /> solving massive problems and
          having tons of fun doing it!
        </p>

        <p className="mb-5">
          When not programming, I enjoy watching anime or reading manga while
          critiquing its narrative&apos;s strengths or flaws. Some of my
          favorites are Hunter × Hunter and Toaru.
        </p>

        <p className="mb-5">
          You can reach me on{" "}
          <a
            className="underline-decoration-blue hover:text-blue-500"
            href={data.site.siteMetadata.twitter}
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          ,{" "}
          <a
            className="underline-decoration-blue hover:text-blue-500"
            href={data.site.siteMetadata.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          ,{" "}
          <a
            className="underline-decoration-blue hover:text-blue-500"
            href={data.site.siteMetadata.github}
            target="_blank"
            rel="noreferrer"
          >
            GitHub
          </a>,{" "}
          <a
            className="underline-decoration-blue hover:text-blue-500"
            href={data.site.siteMetadata.reddit}
            target="_blank"
            rel="noreferrer"
          >
            Reddit
          </a>
          , or by mail at{" "}
          <span className="underline-decoration-blue">
            {data.site.siteMetadata.email}
          </span>
          .
        </p>
      </Section>

      <Section>
        <header>
          <p className="mb-0 text-lg text-indigo-500 font-semibold">
            What I'm Up To
          </p>
          <h2 className="text-xl font-bold md:text-3xl dark:text-gray-300 mb-8">
            My Current Goals
            <Period />
          </h2>
        </header>

        <div className="relative grid gap-8 row-gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
          {goals.map((goal) => {
            return (
              <div
                key={goal.goal}
                className="duration-300 transform bg-white dark:bg-gray-900 rounded-lg border shadow-lg relative"
              >
                {goal.percentage === 100 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 absolute right-1 top-1 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 absolute right-1 top-1 text-yellow-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                <div className="p-5">
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-lg font-bold leading-5 dark:text-gray-300">
                      {goal.goal}
                    </p>
                  </div>

                  <ul className="list-disc list-inside text-sm dark:text-gray-300 mb-0">
                    {goal.tasks?.map((task, index, arr) => {
                      return (
                        <li
                          className={clsx(
                            "list-style-circle",
                            index !== arr.length - 1 && "mb-1"
                          )}
                          key={task}
                        >
                          {task}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </Section>

      <Section>
        <header>
          <p className="mb-0 text-lg text-blue-600 font-semibold">
            Work Experience
          </p>
          <h2 className="text-xl font-bold md:text-3xl dark:text-gray-300 mb-8">
            Professional Work
            <Period />
          </h2>
        </header>

        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-5">
          {jobs.map((job, index) => (
            <div
              className="flex rounded-lg shadow-lg dark:bg-gray-900 border"
              key={job.workDates}
            >
              {getImageDataByHandle(data.allGraphCmsAsset, job.logoSrc) ? (
                <GatsbyImage
                  className="h-32 w-32 p-2"
                  loading="lazy"
                  alt={job.alt || job.name}
                  image={getImageDataByHandle(
                    data.allGraphCmsAsset,
                    job.logoSrc
                  )}
                />
              ) : (
                <img
                  loading="lazy"
                  className="h-32 w-32 p-2"
                  src={job.logoSrc}
                  alt={job.alt || job.name}
                />
              )}

              <div
                className={clsx(
                  "px-3 py-4 relative flex flex-col gap-1",
                  job.className
                )}
              >
                <h3 className={clsx("font-semibold dark:text-gray-300")}>
                  {job.name}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 text-md">
                  {job.workDates}
                </p>
                <p className="dark:text-gray-300 text-md">{job.jobTitle}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>

      <LatestPosts />

      <Watchlist />

      <Section>
        <header>
          <p className="mb-0 text-lg text-green-500 font-semibold">My Q&A</p>
          <h2 className="mb-8 text-xl font-extrabold md:text-3xl dark:text-gray-300">
            Interesting Facts About Me
            <Period />
          </h2>
        </header>

        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-10 grid-flow-row auto-rows-max">
          {questions.map((qa: QA) => {
            return (
              <div key={qa.question}>
                <h3 className="mb-3 font-semibold text-xl dark:text-gray-200">
                  {qa.question}
                </h3>
                {Array.isArray(qa.answer)
                  ? qa.answer.map((ans, index, arr) => {
                      return (
                        <React.Fragment key={ans}>
                          <p className="text-base dark:text-gray-300">{ans}</p>
                          {index < arr.length - 1 && <br />}
                        </React.Fragment>
                      );
                    })
                  : qa.answer}
              </div>
            );
          })}
        </div>
      </Section>

      <SupportMe />

      <Section>
        <p className="mb-0 text-lg text-pink-500 font-semibold">
          Got A Question
        </p>
        <h2 className="mb-4 text-xl font-bold md:text-3xl dark:text-gray-300">
          Ask Me Anything
          <Period />
        </h2>
        <Comments slug={routes.aboutMe} />
      </Section>
    </>
  );
};

export default Me;
