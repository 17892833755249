import * as React from "react";
import {Period} from "../../Period";
import {graphql, Link, useStaticQuery} from "gatsby";
import {BlogTag} from "../../BlogTag";
import {buildArticleRoute, buildCategoryRoute, routes,} from "../../../util/routes";
import {Section} from "../../Section";
import {GatsbyImage} from "gatsby-plugin-image";
import {convertDate} from "../../../util/time";
import clsx from "clsx";
import {ArticleBlock} from "../../ArticleBlock";
import {addDecimalToThousands} from "../../../util/stringUtil";
import {useGetArticleViews} from "../../../hooks/useGetArticleViews";

const LatestPosts = () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const data = useStaticQuery<GatsbyTypes.GetLatestArticlesQuery>(graphql`
    query GetLatestArticles {
      allGraphCmsArticle(
        limit: 7
        sort: { fields: created, order: DESC }
        filter: { stage: { eq: PUBLISHED } }
      ) {
        nodes {
          slug
          tags {
            label
          }
          title
          created
          updated
          readTimeMinutes
          contentImage {
            image {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                width: 733
                height: 384
              )
              alt
            }
          }
          excerpt
          category {
            label
          }
        }
      }
    }
  `);

    let slugs = [];

    for (let article of data.allGraphCmsArticle.nodes) {
        slugs.push(article.slug);
    }

    const {isLoading, views} = useGetArticleViews(slugs);

    return (
        <Section>
            <div className="flex mb-8">
                <header>
                    <p className="mb-0 text-lg text-red-500 font-semibold">
                        Latest Posts
                    </p>
                    <h2 className=" text-2xl md:text-3xl font-extrabold leading-tight dark:text-gray-300">
                        My Latest Writings
                        <Period/>
                    </h2>
                </header>

                <Link
                    className="hidden md:inline-flex font-medium hover:bg-blue-500 hover:text-white py-2 px-2 group rounded items-center text-blue-500 focus:ring-4 mr-auto md:ml-auto md:mr-0 my-auto dark:text-gray-300"
                    to={routes.blog}
                >
                    View all posts
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7 ml-2 text-blue-500 group-hover:text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </Link>
            </div>

            <div className="relative mb-20 gap-5 hidden md:grid grid-cols-3">
                <Link
                    to={buildArticleRoute(data.allGraphCmsArticle.nodes[0].slug)}
                    className="h-full relative text-center col-span-2"
                >
                    <GatsbyImage
                        imgClassName="object-contain"
                        className="w-full h-96 rounded-xl hover:opacity-75"
                        alt={
                            data.allGraphCmsArticle.nodes[0].contentImage?.image?.alt == null
                                ? data.allGraphCmsArticle.nodes[0].contentImage?.image?.alt
                                : ""
                        }
                        image={
                            data.allGraphCmsArticle.nodes[0].contentImage.image
                                .gatsbyImageData
                        }
                    />
                    {new Date(data.allGraphCmsArticle.nodes[0].created) > oneMonthAgo && (
                        <span className="inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-white bg-green-500 rounded-sm absolute right-2 top-2">
                  New
              </span>
                    )}
                    <span
                        className="px-2 py-1 text-white bg-gray-900 dark:text-gray-300 dark:bg-gray-800 text-sm rounded absolute right-2 bottom-2 bg-opacity-60 border">
            {data.allGraphCmsArticle.nodes[0].readTimeMinutes} min read
          </span>
                </Link>

                <div className="my-auto max-w-xl">
          <span className="flex gap-2">
            <Link
                to={buildCategoryRoute(
                    data.allGraphCmsArticle.nodes[0].category.label
                )}
                className="text-md font-medium text-blue-500 rounded-sm mb-0 hover:text-green-500"
            >
              {data.allGraphCmsArticle.nodes[0].category.label}
            </Link>
          </span>
                    <h3 className="mb-2 text-3xl font-bold leading-none">
                        <Link
                            to={buildArticleRoute(data.allGraphCmsArticle.nodes[0].slug)}
                            className="hover:text-green-500 dark:hover:text-green-500 dark:text-gray-300"
                            style={{lineHeight: "1.15"}}
                        >
                            {data.allGraphCmsArticle.nodes[0].title}
                        </Link>
                    </h3>
                    <div className="text-sm flex items-center dark:text-gray-200 gap-3 mb-2">
                        <time className="inline-flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-1 h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                            {convertDate(data.allGraphCmsArticle.nodes[0].created)}
                        </time>

                        <div className="inline-flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-1 h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                            </svg>
                            {isLoading ? 0 : addDecimalToThousands(views[data.allGraphCmsArticle.nodes[0].slug])} views
                        </div>
                    </div>

                    <p className="mb-3  font-normal text-gray-600 dark:text-gray-300">
                        {data.allGraphCmsArticle.nodes[0].excerpt}
                    </p>

                    <div className="flex flex-wrap gap-1">
                        {data.allGraphCmsArticle.nodes[0].tags.map((tag) => {
                            return <BlogTag label={tag.label} key={tag.label}/>;
                        })}
                    </div>
                </div>
            </div>

            <div
                className="grid sm:grid-cols-2 gap-x-5 gap-y-10 md:gap-y-20 md:gap-x-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                {data.allGraphCmsArticle.nodes.map((article, index) => {
                    article.views = isLoading ? 0 : views[article.slug];
                    return (
                        <ArticleBlock
                            article={article}
                            className={clsx(index === 0 && "md:hidden")}
                            key={article.title}
                        />
                    );
                })}
            </div>

            <div className="mt-7 text-center block md:hidden w-full">
                <Link to={routes.blog} className="flex justify-center w-full mx-auto text-center py-2 px-3 bg-red-500 text-white text-sm font-semibold rounded-md shadow focus:outline-none">
                    View all posts
                </Link>
            </div>
        </Section>
    );
};

export {LatestPosts};
