import React from "react";
import {PaypalButton} from "./PaypalButton";
import {Period} from "./Period";
import {Section} from "./Section";
import {graphql, useStaticQuery} from "gatsby";
import {KofiButton} from "./KofiButton";
import {PatreonButton} from "./PatreonButton";

const SupportMe = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
        kofi
        patreon
        }
      }
    }
  `);

  return (
    <Section>
      <header>
        <p className="mb-0 text-lg text-blue-600 font-semibold">Support Me</p>
        <h2 className="text-xl font-bold md:text-3xl dark:text-gray-300 mb-8">
          Help Support What I Do
          <Period />
        </h2>
      </header>

      <div className="flex flex-wrap gap-3">
        <KofiButton />
        <PatreonButton />
        <PaypalButton />
      </div>
    </Section>
  );
};

export { SupportMe };
